<template>
    <div class="inner-section">
        <b-row>
            <b-col md="12">
                <body-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('externalTraining.id_card')}}</h4>
                    </template>
                    <template v-slot:body>
                      <select-column-check :labelData="labelData" :search="search" :columns="columns" />
                      <b-overlay :show="loadingState">
                          <b-row>
                            <b-col md="12" class="table-responsive">
                              <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                <template v-slot:cell(index)="data">
                                {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(action)="data">
                                  <b-button v-b-modal.modal-4 variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-print m-0"></i></b-button>
                                </template>
                              </b-table>
                              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                <b-pagination
                                  v-model="pagination.currentPage"
                                  :perPage="search.limit"
                                  :total-rows="pagination.totalRows"
                                  @input="searchData"
                                />
                              </div>
                            </b-col>
                          </b-row>
                      </b-overlay>
                    </template>
                </body-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="sm" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </div>
</template>
<script>
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeIdCard } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
      return {
        search: {
          limit: 20,
          circular_memo_no: '',
          training_category_id: 0,
          training_type_id: 0,
          training_title_id: 0
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '25%' } },
          { labels: 'globalTrans.office', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
          { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '20%' } },
          { labels: 'globalTrans.print', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 0)
      },
      formTitle () {
        return this.$t('externalTraining.id_card')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
            return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'org_bn' },
            { key: 'office_bn' },
            { key: 'training_type_bn' },
            { key: 'training_title_bn' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'org' },
            { key: 'office' },
            { key: 'training_type' },
            { key: 'training_title' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
    },
    async created () {
      this.loadData()
    },
    mounted () {
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      loadData () {
        this.search.registration_for = this.$store.state.ExternalUserService.trainingPanel.panel
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, traineeIdCard, params).then(response => {
          if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.organization_id))
          const orgReportHeadObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.organization_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
            orgData.org_address = orgObj.address
            orgData.org_address_bn = orgObj.address_bn
            orgData.right_logo = orgReportHeadObj.right_logo
          } else {
            orgData.org = ''
            orgData.org_bn = ''
            orgData.org_address = ''
            orgData.org_address_bn = ''
            orgData.right_logo = ''
          }
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
            officeData.address = officeObj.address
            officeData.address_bn = officeObj.address_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
            officeData.address = ''
            officeData.address_bn = ''
          }
          // const designationObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          // const designationData = {}
          // if (typeof designationObj !== 'undefined') {
          //   designationData.designation = designationObj.text_en
          //   designationData.designation_bn = designationObj.text_bn
          // } else {
          //   designationData.designation = ''
          //   designationData.designation_bn = ''
          // }
          const trainingTypeListObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(obj => obj.value === item.training_type_id)
          const trainingTypeData = {
            training_type: trainingTypeListObj?.text_en,
            training_type_bn: trainingTypeListObj?.text_bn
          }
          const trainingTitleListObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(obj => obj.value === item.training_title_id)
          const trainingTitleData = {
            training_title: trainingTitleListObj?.text_en,
            training_title_bn: trainingTitleListObj?.text_bn
          }

          return Object.assign({}, item, orgData, officeData, /* designationData, */ trainingTypeData, trainingTitleData)
        })
        return listData
      }
    }
}
</script>
